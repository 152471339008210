<template>
	<div class="flex-column full-height bg-gray-light position-relative">
		<div class="pa-10 bg-white mb-10">
			<DatePicker :date="item_search.date" @click="setDate($event)" ></DatePicker>
		</div>
		<div class="bg-white full-height overflow-y-auto">
			<table
				v-if="items.length > 0"
				class="table table-even"
			>
				<thead>
					<tr>
						<th>가맹점</th>
						<th>상점</th>
						<th>정산금액</th>
						<th>차감금액</th>
						<th>정산여부</th>
						<th>지급여부</th>
					</tr>
				</thead>
				<tbody>

					<tr
						v-for="(item, index) in items"
						:key="'item_' + index"
						class=""
					>
						<td>{{ item.seller_id }}</td>
						<td>{{ item.shop_name }}</td>
						<td>{{ item.amount | makeComma }}원</td>
						<td>{{ item.minus_amount | makeComma }}원</td>
						<td>{{ item.is_settlement_name }}</td>
						<td>{{ item.is_deposit_name }}</td>
					</tr>
				</tbody>
			</table>
			<Empty
				v-else
			></Empty>
		</div>

		<div
			v-if="false && user.agency_type == 'distributor'"
			class="position-absolute flex-column"
			style="right: 20px; bottom: 10px; z-index: 1"
		>
			<button
				v-if="is_delete"
				@click="onDelete"
			><v-icon large class="color-red ">mdi-delete-circle</v-icon></button>
			<button
				v-if="is_deposit"
				@click="onDeposit"
				class="mt-5"
			><v-icon large class="color-blue ">mdi-play-circle-outline</v-icon></button>
			<button
				@click="toItem"
				class="mt-5"
			><v-icon large class="color-blue">mdi-plus-circle</v-icon></button>
		</div>

		<PopupConfirm
			v-if="is_on_deposit"

			@cancel="is_on_deposit = false"
			@click="onPin"
		>
			<template
				v-slot:title
			>정산 지급</template>
			<template
				v-slot:main-txt
			>가맹점 정산금액을 지급하시겠습니까?</template>
		</PopupConfirm>

		<PopupConfirm
			v-if="is_on_delete"

			@cancel="is_on_delete = false"
			@click="postSettlementDelete"
		>
			<template
				v-slot:title
			>정산 취소</template>
			<template
				v-slot:main-txt
			>가맹점 정산내역을 취소하시겠습니까?</template>
		</PopupConfirm>
	</div>
</template>

<script>
import Empty from "../Layout/Empty";
import DatePicker from "../../components/DatePicker";
import PopupConfirm from "../Layout/PopupConfirm";
export default {
	name: 'CalculateList'
	, components: {PopupConfirm, DatePicker, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '정산 내역'
				, top: false
				, title: true
				, bottom: true
			}
			, items: []
			, item_search: {
				date: this.$route.params.date ? this.$route.params.date : this.$date.getToday('-')
			}
			, is_on_deposit: false
			, is_on_delete: false
			, is_settlement: false
		}
	}
	, computed: {
		is_deposit: function(){

			let i = 0
			this.items.filter ( (item) => {
				if((item.is_settlement && item.is_settlement != '1') || (item.is_deposit && item.is_deposit != '0')){
					i++
				}
			})
			return this.is_settlement && this.items.length > 0 && i == 0 ? true : false
		}
		, is_delete: function(){
			let i = 0

			this.items.filter ( (item) => {
				if(item.is_deposit && item.is_deposit != '0'){
					i++
				}
			})

			return this.is_settlement && this.items.length > 0 && i == 0 ? true : false
		}
	}
	, methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			let url = ''
			if(this.user.agency_type == 'distributor'){
				url = 'member/getCalculate'
			}else{
				url = 'user/getCalculate'
			}
			url = 'user/getCalculate'
			try{
				const result = await this.$Axios({
					method: 'get'
					,url: url
					,data: {
						date: this.item_search.date
					}
				})

				if(result.success){
					this.items = result.data.list
					this.is_settlement = result.data.is_settlement
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toItem: function(){
			this.$bus.$emit('to', { name: 'CalculateItem', params: { date: this.item_search.date}})
		}
		, setDate: function(date){
			this.item_search.date = date
			this.getData()
		}
		, onDeposit: function(){
			this.is_on_deposit = true
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$emit('onPin', { type: 'check'})
			this.$bus.$on('pinCallback', (call) => {
				this.postPinCheck(call)
			})
		}

		, postPinCheck: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
					}
				})
				if(result.success){
					await this.postDeposit()
					this.$bus.$emit('offPin')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postDeposit: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$Axios({
					method: 'post'
					,url: 'member/postDeposit'
					,data: {
						date: this.item_search.date
					}
				})

				if(result.success){
					this.is_on_deposit = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onDelete: function(){
			this.is_on_delete = true
		}
		, postSettlementDelete: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$Axios({
					method: 'post'
					,url: 'member/postSettlementDelete'
					,data: {
						date: this.item_search.date
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.is_on_delete = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>